

/*
body {
    background: url(assets/blurry-gradient-haikei.svg) no-repeat;
    background-size: 100vw auto;
}

@media (orientation: portrait) {
    body {
        background-size: auto 100vh;
    }
}
*/

@font-face {
    font-family: "N27";
    src: local("N27"),
     url("./fonts/n27-regular-webfont.ttf") format("truetype");
    font-weight: regular;
    }
@font-face {
    font-family: "Basier Mono";
    src: local("Basier Mono"), url("./fonts/basiersquaremono-regular-webfont.ttf") format("truetype");
}
